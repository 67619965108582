.article_card {
	max-width: 350px;
	min-width: 300px;
	min-height: 500px;
    max-height: 600px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* border: 1px solid gray; */
	padding: 20px;
	/* background-color: rgb(231, 231, 231); */
	/* position: absolute; */
}

.article_card_moved_left {
	transition: all 0.5s ease-out;
	transform: translateX(-300px);
	opacity: 0;
}

.article_card_moved_back {
	transform: translateX(0) rotate(0);
	opacity: 0;
}

.article_card_moved_right {
	transition: all 0.5s ease-out;
	transform: translateX(300px);
	opacity: 0;
}

.article_card_info {
	display: flex;
	flex-direction: column;
	gap: 20px;
	line-height: 1.5;
}

.article_card_info_title {
	font-size: 18px;
}

.article_card_info_authors {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.article_card_info_author {
	border: 1px solid #b9b9b9;
	border-radius: 5px;
	padding: 1px 3px;
	font-size: 14px;
}

.article_card_info_magazine {
	color: #878787;
	font-size: 14px;
}

.article_card_info_annonation {
	font-size: 15px;
	max-height: 200px;
	overflow: auto;
}

.article_card_buttons {
	display: flex;
	justify-content: space-around;
}

/* .article_card_buttons > button {
	font-size: 30px;
	border: none;
	cursor: pointer;
} */
