:root {
	--blue: #aceafb;
}

/* Reset and base styles  */
* {
	padding: 0px;
	margin: 0px;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Links */

a {
	color: black;
}

a,
a:link,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

body {
	position: relative;
	color: black;
	background-color: #f5f4f4;
	/* height: 100vh; */
	font-family: sans-serif;
	display: flex;
}

#root {
	width: 100vw;
}

.center_content_page {
	width: 100vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.column_form {
	display: flex;
	flex-direction: column;
}

.column_form > input {
	padding-left: 4px;
	border: 1px solid gray;
}

.column_form > input,
.column_form > button {
	border-radius: 10px;
	font-size: 14px;
}

.column_form > button {
	border: none;
	background-color: var(--blue);
}

.general_page {
	/* height: 90vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.header {
	height: 50px;
	display: flex;
	align-items: center;
	padding: 0 20px;
	background-color: var(--blue);
	justify-content: space-between;
}

.header .user_part {
	display: flex;
	align-items: center;
	gap: 20px;
}

.header .user_part button {
	padding: 10px;
	background-color: brown;
	border: none;
	color: white;
}

.search {
	display: flex;
	align-items: center;
}

.search img {
	max-width: 100%;
}

.search button {
	width: 30px;
	background: none;
	border: none;
}

.form_error {
	color: red;
}

.form_success {
	color: green;
}

.saved_articles h1 {
	margin-top: 30px;
	text-align: center;
}

/* .like_button,
.dislike_button {
	width: 90px;
	height: 50px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	border: none;
	cursor: pointer;
} */

.like_button {
	background-color: #4eafff;
}

.dislike_button {
	background-color: #ff543d;
}

.like_button:disabled,
.dislike_button:disabled {
	opacity: 0.3;
	cursor: not-allowed;
}

.article_card_list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 30px;
	padding: 20px;
	/* flex-wrap: wrap;
	flex: auto */
}

.modal_content {
	min-width: 350px;
	max-width: 600px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #f1f1f1;
	padding: 25px;
	border-radius: 1em;
}

.cross_button{
	width: 20px;
	height: 20px;
	position: absolute;
	right: 10px;
	top: 37%;
	color: red;
	font-weight: 900;
	cursor: pointer;
	font-size: 15px;
	padding: 0;
}

@media screen and (max-width: 1017px) {
	.article_card_list {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 686px) {
	.article_card_list {
		grid-template-columns: 1fr;
	}
}
