.article_list {
	margin: 0 auto;
	max-width: 700px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 10px;
	margin-top: 40px;
	list-style: none;
}

.article_list_item {
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 10px 40px 10px 10px;
	position: relative;
}
